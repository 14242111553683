import React, { useEffect, useRef, useState } from 'react';
import { isNull } from 'lodash';

import { CONTACT_US, FUNCTIONALITIES, PRODUCT, SECTORS, TECHNOLOGY } from './types';
import { DESKTOP } from 'constants/deviceType';
import { getDeviceType } from 'helper/getDeviceType';

import Header from './Header';
import Product from './Product';
import Functionalities from './Functionalities';
import Sectors from './Sectors';
import Technology from './Technology';
import ContactUs from './ContactUs';
import LoadingPage from './LoadingPage';
import Footer from './Footer';

import styles from './styles.module.sass';

const Layout = () => {
    const productRef = useRef(null);
    const functionalitiesRef = useRef(null);
    const sectorsRef = useRef(null);
    const technologyRef = useRef(null);
    const contactUsRef = useRef(null);

    const [selectedComponent, setSelectedComponent] = useState('');
    const [deviceType, setDeviceType] = useState(DESKTOP);

    const scrollIntoComponent = (name) => {
        setSelectedComponent(name);

        if (isNull(productRef.current) ||
            isNull(functionalitiesRef.current) ||
            isNull(sectorsRef.current) ||
            isNull(technologyRef.current) ||
            isNull(contactUsRef.current)) return;

        switch (name) {
            case PRODUCT:
                window.scrollTo({ top: productRef.current.offsetTop });
                break;
            case FUNCTIONALITIES:
                window.scrollTo({ top: functionalitiesRef.current.offsetTop });
                break;
            case SECTORS:
                window.scrollTo({ top: sectorsRef.current.offsetTop });
                break;
            case TECHNOLOGY:
                window.scrollTo({ top: technologyRef.current.offsetTop });
                break;
            case CONTACT_US:
                window.scrollTo({ top: contactUsRef.current.offsetTop });
                break;
            default:
                window.scrollTo({ top: productRef.current.offsetTop });
                break;
        }
    }

    const changeDeviceType = () => {
        setDeviceType(getDeviceType(document.body.clientWidth));
    }

    useEffect(() => {
        setDeviceType(getDeviceType(document.body.clientWidth));
        window.addEventListener('resize', changeDeviceType);
    }, []);

    return (
        <div className={styles.layout}>
            <LoadingPage />
            <Header
                scrollIntoComponent={scrollIntoComponent}
                selectedComponent={selectedComponent}
                deviceType={deviceType} />
            <Product
                ref={productRef}
                scrollIntoComponent={scrollIntoComponent}
                deviceType={deviceType} />
            <Functionalities
                ref={functionalitiesRef}
                deviceType={deviceType} />
            <Sectors
                ref={sectorsRef}
                deviceType={deviceType} />
            <Technology
                ref={technologyRef}
                deviceType={deviceType} />
            <ContactUs
                ref={contactUsRef}
                deviceType={deviceType} />
            <Footer />
        </div>
    )
}

export default Layout;