import React from 'react';

import { CONTACT_US } from './types';
import { MOBILE } from 'constants/deviceType';

import background_animation from '../assets/animations/background_animation.mp4';
import background_animation_mobile from '../assets/animations/background_animation_mobile.mp4';

import styles from './styles.module.sass';

const Product = React.forwardRef((props, ref) => {

    const isMobile = props.deviceType === MOBILE;

    return (
        <div className={styles.product} ref={ref} >
            <video autoPlay loop muted={true} playsInline>
                <source src={isMobile ? background_animation_mobile : background_animation} type='video/mp4' />
                Your browser does not support the video tag.
            </video>
            <div className={styles.content}>
                {!isMobile && <h1>Antifraud solution</h1>}
                <h2>We provide software that will address your business needs</h2>
                <button onClick={() => props.scrollIntoComponent(CONTACT_US)}>Book a demo call</button>
            </div>
        </div>
    )
});

export default Product;