import React from 'react';
import { InlineWidget } from 'react-calendly';

import config from 'config';

import { MOBILE } from 'constants/deviceType';

import styles from './styles.module.sass';

const ContactUs = React.forwardRef((props, ref) => {

    const isMobile = props.deviceType === MOBILE;

    return (
        <div className={styles.contactUs} ref={ref}>
            <h1>How to start?</h1>
            {!isMobile && <p>Schedule a demo call to show us what are you facing in the fraud detection area</p>}
            <InlineWidget url={config.calendly_url} styles={{ height: `${isMobile ? '1100px' : '700px'}` }} />
        </div>
    )
});

export default ContactUs;