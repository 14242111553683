import React, { useState } from 'react';

import { CONTACT_US, FUNCTIONALITIES, PRODUCT, SECTORS, TECHNOLOGY } from './types';
import { MOBILE } from 'constants/deviceType';
import { importAsset } from 'helper/helperMethods';

import styles from './styles.module.sass';

const Header = ({ scrollIntoComponent, selectedComponent, deviceType }) => {

    const isMobile = deviceType === MOBILE;

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const mobileScrollIntoComponent = (component) => {
        scrollIntoComponent(component);
        triggerMobileNavbar();
    }

    const triggerMobileNavbar = () => {
        document.body.style.overflow  = isNavbarOpen ? 'auto' : 'hidden';
        setIsNavbarOpen(!isNavbarOpen);
    }

    return (
        <>
            <div className={styles.header}>
                <img src={importAsset('./images/icons/logo.svg')} alt='Logo' />
                {isMobile && <img src={importAsset('./images/icons/mobile-burger.svg')} alt='menu burger' onClick={() => triggerMobileNavbar()} />}
                {
                    !isMobile &&
                    <div className={isMobile ? `${styles.mobileNavbar} ${isNavbarOpen && styles.open}` : styles.navbar}>
                        <button
                            className={`${styles.navButton} ${selectedComponent === PRODUCT && styles.navButtonSelected}`}
                            onClick={() => scrollIntoComponent(PRODUCT)}>
                            Product
                        </button>
                        <button
                            className={`${styles.navButton} ${selectedComponent === FUNCTIONALITIES && styles.navButtonSelected}`}
                            onClick={() => scrollIntoComponent(FUNCTIONALITIES)}>
                            Functionalities
                        </button>
                        <button
                            className={`${styles.navButton} ${selectedComponent === SECTORS && styles.navButtonSelected}`}
                            onClick={() => scrollIntoComponent(SECTORS)}>
                            Sectors
                        </button>
                        <button
                            className={`${styles.navButton} ${selectedComponent === TECHNOLOGY && styles.navButtonSelected}`}
                            onClick={() => scrollIntoComponent(TECHNOLOGY)}>
                            Technology
                        </button>
                        <button
                            onClick={() => scrollIntoComponent(CONTACT_US)}
                            className={styles.bigButton}>
                            Book a demo call
                        </button>
                    </div>
                }
            </div>
            {
                isMobile &&
                <div className={`${styles.mobileNavbar} ${isNavbarOpen && styles.open}`}>
                    <button
                        className={`${styles.navButton} ${selectedComponent === PRODUCT && styles.navButtonSelected}`}
                        onClick={() => mobileScrollIntoComponent(PRODUCT)}>
                        Product
                    </button>
                    <button
                        className={`${styles.navButton} ${selectedComponent === FUNCTIONALITIES && styles.navButtonSelected}`}
                        onClick={() => mobileScrollIntoComponent(FUNCTIONALITIES)}>
                        Functionalities
                    </button>
                    <button
                        className={`${styles.navButton} ${selectedComponent === SECTORS && styles.navButtonSelected}`}
                        onClick={() => mobileScrollIntoComponent(SECTORS)}>
                        Sectors
                    </button>
                    <button
                        className={`${styles.navButton} ${selectedComponent === TECHNOLOGY && styles.navButtonSelected}`}
                        onClick={() => mobileScrollIntoComponent(TECHNOLOGY)}>
                        Technology
                    </button>
                    <button
                        onClick={() => mobileScrollIntoComponent(CONTACT_US)}
                        className={`${styles.navButton} ${selectedComponent === CONTACT_US && styles.navButtonSelected}`}>
                        How to start
                    </button>
                </div>
            }
        </>
    )
}

export default Header;