import React, { useState, useEffect } from 'react';

import styles from './carousel.module.sass';

const ScrollIndicator = React.forwardRef((props, ref) => {

    const [scrollProgress, setScrollProgress] = useState(0);

    const scrollListener = () => {
        if (!ref.current) {
            return;
        }

        const element = ref.current;
        const windowScroll = element.scrollLeft;
        const totalWidth = element.scrollWidth - element.clientWidth;
        if (windowScroll === 0) {
            return setScrollProgress(0);
        }

        if (windowScroll > totalWidth) {
            return setScrollProgress(100);
        }

        setScrollProgress((windowScroll / totalWidth) * 100);
    }

    useEffect(() => {
        ref.current.addEventListener('touchmove', scrollListener);
        ref.current.addEventListener('scroll', scrollListener);
    });

    const renderDots = () => {
        const selectedDotValue = (scrollProgress * props.count) / 100;
        return [...Array(props.count).keys()].map(index => (
            <div
                key={index}
                className={`${styles.dot} ${selectedDotValue >= index && selectedDotValue <= index + 1 && styles.active}`}
            />
        ));
    }

    return (
        <div className={styles.scrollIndicatorContainer}>
            {renderDots()}
        </div>
    )
});

export default ScrollIndicator;