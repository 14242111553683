import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { MOBILE } from 'constants/deviceType';
import { importAsset } from 'helper/helperMethods';

import Carousel from './Global/Carousel/Carousel';

import styles from './styles.module.sass';

const Functionalities = React.forwardRef((props, ref) => {

    const SLIDE_DELTA = 210;

    const isMobile = props.deviceType === MOBILE;

    const cardsRef = useRef(null);
    const [scrollX, setScrollX] = useState(0);
    const [scrollEnd, setScrollEnd] = useState(false);

    const slide = (delta) => {
        cardsRef.current.scrollLeft += delta;
        setScrollX(scrollX + delta);
        setScrollEnd(Math.floor(cardsRef.current.scrollWidth - cardsRef.current.scrollLeft) <= cardsRef.current.offsetWidth);
    }

    const scrollCheck = () => {
        setScrollX(cardsRef.current.scrollLeft);
        setScrollEnd(Math.floor(cardsRef.current.scrollWidth - cardsRef.current.scrollLeft) <= cardsRef.current.offsetWidth);
    };

    useEffect(() => {
        if (cardsRef && cardsRef.current) {
            setScrollEnd(Math.floor(cardsRef.current.scrollWidth - cardsRef.current.scrollLeft) <= cardsRef.current.offsetWidth);
        }
    }, []);

    const cardsData = [
        {
            icon: 'check-circle',
            title: 'Claim scoring',
            description: 'Fraud risk assessment. Usage of advanced analytics modeling, data-driven business rules, and Social network analysis provides a ready-to-go set of claims to investigate.'
        },
        {
            icon: 'sliders',
            title: 'Configurable application and customized workflow',
            description: 'Support for customized workflows, custom claim statuses and configurable transition conditions.'
        },
        {
            icon: 'users',
            title: 'Score-based auto-assignment to users',
            description: 'An automatic process that assigns the highest fraud probability claims to users based on their capacity.'
        },
        {
            icon: 'key',
            title: 'Access control',
            description: 'Possible restriction of access to specific roles only, limiting visibility of claims, reports, attachments. Availability to limit access to certain types of claims (according to users’ competence).'
        },
        {
            icon: 'file-text',
            title: 'Reporting module',
            description: 'Support for the reports tuned to reflect your KPIs and their current fulfillment.'
        },
        {
            icon: 'flag',
            title: 'Customized application display language',
            description: 'Multi-language user interface switch.'
        },
        {
            icon: 'minimize',
            title: 'Custom integrations of all data in one place',
            description: 'Possibility to integrate with internal systems as well as with external data sources & providers.'
        }
    ]

    const renderCards = () => {
        return cardsData.map((card) => {
            return (
                <div className={styles.card} key={uuid()}>
                    <img src={importAsset(`./images/icons/${card.icon}.svg`)} alt={`${card.icon}`} />
                    <p className={styles.title}>{card.title}</p>
                    <p className={styles.description}>{card.description}</p>
                </div>
            )
        })
    }

    return (
        <div className={styles.functionalities} ref={ref}>
            <div>
                <h1>What do we offer?</h1>
                <div className={styles.textColumn}>
                    <div>
                        <h2>High fraud detection level</h2>
                        <p>Platform provides <strong>above average fraud
                            detection level</strong> when compared to other
                            known market solutions. A low false-positive ratio and carefully calibrated prioritization
                            allow your team to focus on the most
                            fraudulent claims.
                        </p>
                    </div>
                    <div>
                        <h2>Holistic approach to fraud management</h2>
                        <p><strong>Online scoring and event-driven</strong> architecture
                            allow us to provide a common platform for
                            a conventional claim process as well as
                            online <strong>self-service channels</strong>.
                        </p>
                    </div>
                </div>
                <div className={styles.textColumn}>
                    <div className={styles.textContainer}>
                        <h2>Comprehensive antifraud solution</h2>
                        <p>One solution to address all fraud-related
                            processes including <strong>fraud detection</strong> as well
                            as <strong>fraud management</strong> in your organization.
                            <strong> Advanced analytics</strong> combined with powerful
                            and <strong>flexible BPM</strong>.
                        </p>
                    </div>
                    <div>
                        <h2>Easy to start</h2>
                        <p>An experienced team of technical experts, as
                            well as data engineers, will set up initial
                            integration using our <strong>standardized and well-documented
                                data models and APIs.</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.mainFunctionalities}>
                <h2>Main functionalities</h2>
                {
                    isMobile ?
                        <Carousel count={cardsData.length} className={styles.carousel}>
                            {renderCards()}
                        </Carousel>
                        :
                        <>
                            <div className={styles.cardsContainer} ref={cardsRef} onScroll={scrollCheck}>
                                {renderCards()}
                            </div>
                            <div className={styles.scrollButtons}>
                                <button className={`${styles.arrowButton} ${scrollX <= 0 && styles.hidden}`} onClick={() => slide(-SLIDE_DELTA)}>
                                    <i className='custom-icon-arrow-left' />
                                </button>
                                <button className={`${styles.arrowButton} ${scrollEnd && styles.hidden}`} onClick={() => slide(SLIDE_DELTA)}>
                                    <i className='custom-icon-arrow-right' />
                                </button>
                            </div>
                        </>
                }
            </div>
        </div>
    )
});

export default Functionalities;