import React from 'react';
import { v4 as uuid } from 'uuid';

import { MOBILE } from 'constants/deviceType';

import Carousel from './Global/Carousel/Carousel';

import styles from './styles.module.sass';

const Sectors = React.forwardRef((props, ref) => {

    const isMobile = props.deviceType === MOBILE;

    const cardsData = [
        {
            title: 'Banking',
            description: 'The solution enables the analysis of potential fraud objects, e.g. loan applications. The most widely used is Social network analysis, which will allow the use of information about the client in a new way to analyze the environment of a given loan application.'
        },
        {
            title: 'Insurance',
            description: 'Use in the area of ​​analyzing reported claims. Extensive integration options allow the use of information available in external systems (e.g. vehicle information). Integration with Google services allows you to obtain additional information for the Investigator (including location analysis and image recognition on attachments to the case).'
        },
        {
            title: 'Healthcare',
            description: 'Detecting frauds in the area of ​​patient health claims. Use of existing patient information and additional integrations to obtain scoring. Possibility to map the body elements that the claim relates to.'
        }
    ];

    const renderCards = () => {
        return cardsData.map((card) => {
            return (
                <div className={styles.card} key={uuid()}>
                    <h2>{card.title}</h2>
                    <p>{card.description}</p>
                </div>
            )
        });
    }

    return (
        <div className={styles.sectors} ref={ref}>
            {!isMobile &&
                <div className={styles.imageTitle}>
                    <h1>Sectors</h1>
                </div>
            }
            <div className={styles.content}>
                {isMobile && <h1>Sectors</h1>}
                <p>ICFraud enables the use in various sectors as the solution may be widely personalized. It is possible to define the required custom integrations with systems from many areas. So far, the application is prepared to work in the following:</p>
                {
                    isMobile ?
                        <Carousel count={cardsData.length} className={styles.carousel}>
                            {renderCards()}
                        </Carousel>
                        :
                        <div className={styles.cardsContainer}>
                            {renderCards()}
                        </div>
                }
            </div>
        </div>
    )
});

export default Sectors;