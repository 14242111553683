import React from 'react';

import styles from './styles.module.sass';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <a href='https://itsquad.pl/'><img src={require('../assets/images/itsquad.png')} alt='ITSquad logo' /></a>
            <div>
                <h1>ICFraud</h1>
                <p>+48 884 889 995<br />ICFraud@itsquad.pl</p>
            </div>
        </div>
    )
}

export default Footer;